export const faceLivenessDictionary = {
  // use default strings for english
  en: null,
  br: {
    photosensitivyWarningHeadingText: "Aviso de fotossensibilidade",
    photosensitivyWarningBodyText:
      "Esta verificação mostra luzes coloridas. Tenha cuidado se você for fotossensível.",
    goodFitCaptionText: "Bom ajuste",
    tooFarCaptionText: "Longe demais",
    hintCenterFaceText: "Centralize o rosto",
    startScreenBeginCheckText: "Verificar",
    photosensitivyWarningInfoText:
      "Algumas pessoas podem ter convulsões epilépticas quando expostas a luzes piscantes ou coloridas. Se você ou alguém de sua família tem epilepsia, é importante ter cuidado com esse tipo de estímulo.",
    waitingCameraPermissionText:
      "Aguardando autorização para acessar a câmera.",
  },
  es: {
    photosensitivyWarningHeadingText: "Advertencia de fotosensibilidad",
    photosensitivyWarningBodyText:
      "Esta verificación muestra luces de colores. Tenga cuidado si es fotosensible.",
    goodFitCaptionText: "Buen ajuste",
    tooFarCaptionText: "Demasiado lejos",
    hintCenterFaceText: "Centre la cara",
    startScreenBeginCheckText: "Verificar",
    photosensitivyWarningInfoText:
      "Algunas personas pueden tener convulsiones epilépticas cuando están expuestas a luces parpadeantes o de colores. Si usted o alguien de su familia tiene epilepsia, es importante tener cuidado con este tipo de estímulo.",
    waitingCameraPermissionText:
      "Esperando autorización para acceder a la cámara.",
  },
};
