import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Typography, withStyles } from "@material-ui/core";
import Moment from "moment";
import React, { Component } from "react";
import { CustomView, isIOS } from "react-device-detect";
import { FormattedMessage, IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import * as actions from "../actions";
import performRequest from "../configAxios";
import customInput, { CustomDateOfBirthPicker } from "../custom/CustomInput";
import customSelect from "../custom/CustomSelect";
import messages_br from "../translations/br.json";
import messages_en from "../translations/en.json";
import messages_es from "../translations/es.json";
import { docNormalizer, asyncValidateRegister as asyncValidate, cnpjNormalizer, cpfNormalizer, numberNormalizer } from "../util";
import { country } from "./components/Terms";
import cookie from "react-cookies";

function FormCheckbox({ input, label, ...props }) {
  return <Checkbox label={label} checked={input.value ? true : false} onChange={input.onChange} {...props} />;
}

const messages = {
  br: messages_br,
  en: messages_en,
  es: messages_es,
};

class Register extends Component {
  state = {
    terms: false,
    policy: false,
    termsButton: false,
    typePf: true,
    lang: "", // default em inglês caso o usuario não tenha preenchido o form ainda (inglês não tem pasta)
    locale: "en",
    changingLang: false,
  };

  typeRegister = [
    { value: "NATURAL_PERSON", input: "app.naturalPerson" },
    { value: "COMPANY", input: "app.person.legal" },
  ];

  languages = [
    { value: "en", input: "app.language.en" },
    { value: "br", input: "app.language.br" },
    { value: "es", input: "app.language.es" },
  ];

  documentTypes = {
    SELECT: { value: "", input: "app.form.selectTaxResidence" },
    CUIT: {value: "CUIT", input: "app.cuit"}, //argentina
    TFN: { value: "TFN", input: "app.tfn" }, //australia
    ABN: { value: "ABN", input: "app.abn" }, //australia
    CPF: { value: "CPF", input: "app.cpf" }, //brasil
    CNPJ: { value: "CNPJ", input: "app.cnpj" }, //brasil
    RUT: { value: "RUT", input: "app.rut" }, //chile, uruguai
    NIT: { value: "NIT", input: "app.nit" }, //colombia
    NITE: { value: "NITE", input: "app.nite" }, //costa rica
    RUC: { value: "RUC", input: "app.ruc" }, //equador e panama
    SSN: { value: "SSN", input: "app.ssn" }, //eua
    EIN: {value: "EIN", input: "app.ein"}, //eua
    SIN: { value: "SIN", input: "app.sin" }, //guernsey
    GCRN: { value: "GCRN", input: "app.gcrn" }, //guernsey
    PAN: { value: "PAN", input: "app.pan" }, //india
    GSTIN: { value: "GSTIN", input: "app.gstin" }, //india
    RFC: {value: "RFC", input: "app.rfc"}, //mexico
    IRD: { value: "IRD", input: "app.ird" }, //nova zelandia
    NZBN: { value: "NZBN", input: "app.nzbn" }, //nova zelandia
    NIPC: { value: "NIPC", input: "app.nipc" }, //portugal
    NIF: { value: "NIF", input: "app.nif" }, //portugal
    RIF: { value: "RIF", input: "app.rif" }, //venezuela
  };

  documentTypesByTaxResidence = {
    Argentina: {
      pf: [this.documentTypes.CUIT], //Clave Única de Identificación Tributaria
      pj: [this.documentTypes.CUIT], //Clave Única de Identificación Tributaria
    },
    Austrália: {
      pf: [this.documentTypes.TFN], //Tax File Number
      pj: [this.documentTypes.ABN], //Australian Business Number
    },
    Brasil: {
      pf: [this.documentTypes.CPF],
      pj: [this.documentTypes.CNPJ],
    },
    Colômbia: {
      pf: [this.documentTypes.NIT], //Número de Identificación Tributária
      pj: [this.documentTypes.NIT], //Número de Identificación Tributária
    },
    Uruguai: {
      pf: [this.documentTypes.RUT], //Registro ùnico Tributário
      pj: [this.documentTypes.RUT], //Registro ùnico Tributário
    },
    Portugal: {
      pf: [this.documentTypes.NIF],  //Número de Identificação Fiscal
      pj: [this.documentTypes.NIPC], //Número de Identificação de Pessoa Coletiva
    },
    Chile: {
      pf: [this.documentTypes.RUT], //Rol Único Tributário
      pj: [this.documentTypes.RUT], //Rol Único Tributário
    },
    Venezuela: {
      pf: [this.documentTypes.RIF], //Registro Único de Información Fiscal
      pj: [this.documentTypes.RIF], //Registro Único de Información Fiscal
    },
    México: {
      pf: [this.documentTypes.RFC], //Registro Federal de Contribyentes
      pj: [this.documentTypes.RFC], //Registro Federal de Contribyentes
    },
    Equador: {
      pf: [this.documentTypes.RUC], //Registro Único de Contribuyentes
      pj: [this.documentTypes.RUC], //Registro Único de Contribuyentes
    },
    Índia: {
      pf: [this.documentTypes.PAN], //Permanent Account Numbe
      pj: [this.documentTypes.GSTIN], //Goods and Services Tax Identification Number
    },
    ["Nova Zelândia"]: {
      pf: [this.documentTypes.IRD], //Inland Revenue Department Number
      pj: [this.documentTypes.NZBN], //New Zealand Business Number
    },
    Panamá: {
      pf: [this.documentTypes.RUC], //Registro Único de Contribuyente
      pj: [this.documentTypes.RUC], //Registro Único de Contribuyente
    },
    EUA: {
      pf: [this.documentTypes.SSN], // Social Security Number
      pj: [this.documentTypes.EIN], //Employer Identification Number
    },
    ["Costa Rica"]: { 
      pf: [this.documentTypes.NITE], //Número de Identificación Tributária Especia
      pj: [this.documentTypes.NITE], //Número de Identificación Tributária Especia
    },
    Guernsey: {
      pf: [this.documentTypes.SIN], //Social Insurance Number
      pj: [this.documentTypes.GCRN], //Guernsey Company Registration Number
    }
  };

  normalizersByDocumentType = {
    CPF: cpfNormalizer,
    CNPJ: cnpjNormalizer,
    CUIT: numberNormalizer,
    TFN: numberNormalizer,
    ABN: numberNormalizer,
    RUT: numberNormalizer,
    NIT: numberNormalizer,
    NITE: numberNormalizer,
    SSN: numberNormalizer,
    EIN: numberNormalizer,
    RUC: numberNormalizer,
    SIN: numberNormalizer,
    GCRN: numberNormalizer,
    PAN: numberNormalizer,
    GSTIN: numberNormalizer,
    RFC: numberNormalizer,
    IRD: numberNormalizer,
    NZBN: numberNormalizer,
    NIF: numberNormalizer,
    NIPC: numberNormalizer,
    RIF: numberNormalizer,
  };

  onSubmit = (formProps) => {
    this.setState({ formProps, terms: true });
  };

  accept = () => {
    const { formProps, recaptcha } = this.state;
    const { showSuccessMessage, showErrorMessage, history } = this.props;
    const params = { ...formProps, recaptcha };
    performRequest("POST", "/user/register", params)
      .then((response) => {
        showSuccessMessage("app.register.success");
        history.push("/");
      })
      .catch((e) => {
        if (e.response) {
          showErrorMessage(e.response.data.message);
        } else {
          showErrorMessage(e);
        }
      });
  };

  validateTerms = (value) => (value ? undefined : "app.termsAndConditions");

  onChangeType = (type) => {
    this.props.reset();
    this.props.change("locale", this.state.locale);
    this.setState({ typePf: type == "NATURAL_PERSON" ? true : false, type });
  };

  verifyCallback = (recaptchaToken) => {
    this.setState({ recaptcha: recaptchaToken });
  };

  onChangeLengageTerm(locale) {
    // en, br, es
    this.setState({ changingLang: true });
    if (locale == "br") {
      this.setState({ lang: "/pt", locale });
    } else if (locale == "es") {
      this.setState({ lang: "/es", locale });
    } else {
      this.setState({ lang: "", locale });
    }
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    cookie.save("lclocale", locale, { path: "/", expires });
    setTimeout(() => {
      this.props.initialize({ locale });
      this.setState({ changingLang: false });
    }, 1);
  }

  render() {
    const { handleSubmit, pristine, invalid, submitting, taxResidence, documentType } = this.props;
    const { typePf, locale, changingLang } = this.state;
    const personType = typePf ? "pf" : "pj";
    const documents = taxResidence == null ? [this.documentTypes.SELECT] : this.documentTypesByTaxResidence[taxResidence][personType];
    const documentNormalizer = this.normalizersByDocumentType[documentType];
    return (
      <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 500,
            padding: 16,
          }}
        >
          <div style={{ textAlign: "center", marginTop: 48, marginBottom: 32 }}>
            <img src={process.env.PUBLIC_URL + "/logo/Logo_LC_BANK.png"} alt="logo" width="200" height="200" />
          </div>
          <Typography variant="h5" gutterBottom color="primary" style={{ textAlign: "center" }}>
            Onboarding
          </Typography>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <Field name="locale" label="app.language" component={customSelect} values={this.languages} InputLabelProps={{ shrink: true }} onChange={(e) => this.onChangeLengageTerm(e.target.value)} />
            <Field name="type" label="app.personType" component={customSelect} values={this.typeRegister} InputLabelProps={{ shrink: true }} onChange={(e) => this.onChangeType(e.target.value)} />
            <Field name="name" label="app.name" component={customInput} InputLabelProps={{ shrink: true }} />
            <Field name="taxResidence" label="app.taxResidence" component={customSelect} values={country} InputLabelProps={{ shrink: true }} />
            <Field name="documentType" label="app.documentType" component={customSelect} values={documents} InputLabelProps={{ shrink: true }} />
            <Field name="documentId" label="app.documentIdTax" component={customInput} InputLabelProps={{ shrink: true }} normalize={documentNormalizer} />
            {typePf && (
              <>
                <CustomView condition={isIOS}>
                  <Field name="dateOfBirth" label="app.dateOfBirth" component={customInput} InputLabelProps={{ shrink: true }} type="date" />
                </CustomView>
                <CustomView condition={!isIOS}>
                  <Field name="dateOfBirth" label="app.dateOfBirth" component={CustomDateOfBirthPicker} InputLabelProps={{ shrink: true }} />
                </CustomView>
              </>
            )}
            <Field name="email" label="app.email" component={customInput} InputLabelProps={{ shrink: true }} />
            <Field name="confirmEmail" label="app.confirmEmail" component={customInput} InputLabelProps={{ shrink: true }} />
            <Field name="password" label="app.password" type="password" component={customInput} inputProps={{ maxLength: 20 }} InputLabelProps={{ shrink: true }} />
            <Field name="confirmPassword" label="app.confirmPassword" type="password" component={customInput} inputProps={{ maxLength: 20 }} InputLabelProps={{ shrink: true }} />
            <Field name="agentId" label="app.agentId" component={customInput} InputLabelProps={{ shrink: true }} normalize={numberNormalizer} />
            <Typography gutterBottom variant="caption" color="primary">
              <FormattedMessage id="app.register.text" />{" "}
              <a style={{ color: "#FEC12C" }} href={"https://lux.capital/" + this.state.lang + "/be-a-client"}>
                <FormattedMessage id="app.clickHere" />
              </a>
            </Typography>
            <Button color="primary" variant="contained" type="submit" fullWidth style={{ marginTop: 16 }} disabled={pristine || invalid || submitting || changingLang}>
              <FormattedMessage id="app.signup" />
            </Button>
          </form>
          <Dialog open={this.state.terms} variant="secondary" onClose={(e) => this.setState({ terms: false })}>
            <DialogTitle
              style={{
                color: "black",
                backgroundColor: "rgba(255, 190, 45, 1)",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <FormattedMessage id="app.confirmTerms" />
              </div>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "justify",
                }}
              >
                <Button fullWidth size="small" variant="text" style={{ marginTop: 16 }} component={Button} href={`https://lux.capital${this.state.lang}/terms`} target="_blank">
                  <div style={{ color: "#FEC12C" }}>
                    <FormattedMessage id="app.termsAndConditions.link" />
                  </div>
                </Button>
                <FormControlLabel
                  label={<FormattedMessage id="app.withdrawTerms" />}
                  control={<Field component={FormCheckbox} name="termsAndConditions" color="primary" validate={this.validateTerms} />}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 16, marginBottom: 16 }}
                  disabled={pristine || invalid || submitting || changingLang}
                  onClick={(e) => this.accept()}
                >
                  <FormattedMessage id="app.signup" />
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <div style={{ marginTop: 32 }}>
            <Button fullWidth size="small" variant="text" style={{ marginTop: 16 }} component={Button} href={`https://lux.capital${this.state.lang}/terms`} target="_blank">
              <FormattedMessage id="app.termsAndConditions" />
            </Button>
            <Button fullWidth size="small" variant="text" style={{ marginTop: 16 }} component={Button} href={`https://lux.capital${this.state.lang}/privacy`} target="_blank">
              <FormattedMessage id="app.privacyPolicy" />
            </Button>

            {/* <FormDialog
            title="app.termsAndConditions"
            open={this.state.termsButton}
            variant="secondary"
            noHeader
            onClose={(e) => this.setState({ termsButton: false })}
          >
            <Typography component="div">
							<Terms />
						</Typography>
          </FormDialog>
          <FormDialog
            title="app.privacyPolicy"
            open={this.state.policy}
            variant="secondary"
            noHeader
            onClose={(e) => this.setState({ policy: false })}
          >
            <Typography component="div">
              <Policy />
            </Typography>
          </FormDialog> */}
          </div>
        </div>
      </IntlProvider>
    );
  }
}

const styles = (theme) => ({
  cardHeader: {
    textAlign: "center",
  },
  card: {
    marginTop: "100px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
});

const validate = (values, props) => {
  const errors = {};
  if (!props.anyTouched) return errors;
  if (!values.name) {
    errors.name = "app.form.name.error";
  } else if (values.name.trim().length == 0) {
    errors.name = "app.name.invalid";
  }
  if (!values.locale) {
    errors.locale = "app.form.locale.error";
  }
  if (!values.taxResidence) {
    errors.taxResidence = "app.form.taxResidence.error";
  }
  if (!values.documentType) {
    errors.documentType = "app.form.documentType.error";
  } else if (values.documentType.trim().length == 0) {
    errors.documentType = "app.form.documentType.error";
  }
  if (!values.documentId) {
    errors.documentId = "app.form.documentId.error";
  } else if (values.documentId.trim().length == 0) {
    errors.documentId = "app.documentId.invalid";
  }
  if (!values.dateOfBirth) {
    errors.dateOfBirth = "app.form.dateOfBirth.error";
  } else {
    const inputDate = Moment(values.dateOfBirth);
    const today = Moment();
    const diffYears = today.diff(inputDate, "years");
    if (diffYears < 18) {
      errors.dateOfBirth = "app.form.dateOfBirth.min";
    }
  }
  if (!values.email) {
    errors.email = "app.form.email.error";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+$/i.test(values.email.trim())) {
    errors.email = "app.form.email.invalid";
  } else {
    if (!values.confirmEmail) {
      errors.confirmEmail = "app.form.confirmEmail.error";
    } else if (values.email !== values.confirmEmail) {
      errors.confirmEmail = "app.form.confirmEmail.error.equals";
    }
  }
  if (!values.password) {
    errors.password = "app.password.error";
  } else if (values.password.length < 6) {
    errors.password = "app.password.min";
  } else {
    if (!values.confirmPassword) {
      errors.confirmPassword = "app.confirmPassword.error";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "app.confirmPassword.error.equals";
    }
  }
  if (!values.agentId) {
    errors.agentId = "app.form.agentId.error";
  }
  return errors;
};

const selector = formValueSelector("registerForm");

function mapStateToProps(state) {
  return {
    initialValues: {
      locale: "en",
    },
    auth: state.auth.authenticated,
    currentUser: state.user.user.me,
    taxResidence: selector(state, "taxResidence"),
    documentType: selector(state, "documentType"),
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, actions),
  reduxForm({
    form: "registerForm",
    enableReinitialize: false,
    keepDirtyOnReinitialize: false,
    asyncValidate,
    asyncBlurFields: ["email", "documentId", "taxResidence", "agentId"],
    asyncChangeFields: ["agentId"],
    validate,
    touchOnChange: true,
  })
)(Register);
