import React from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { Loader, ThemeProvider } from "@aws-amplify/ui-react";
import { faceLivenessDictionary } from "../../translations/faceLivenessDictionary";
import {
  getFaceLivenessSessionResult,
  startFaceLivenessSession,
} from "../../actions/Kyc";
import { dispatchErrorMessage } from "../../actions";

const FaceLiveness = ({ onActionComplete, onActionFailed, dispatch }) => {
  const locale = cookie.load("lclocale") ? cookie.load("lclocale") : "en";
  const dictionary = React.useMemo(
    () => faceLivenessDictionary[locale],
    [locale]
  );

  const [loading, setLoading] = React.useState(true);
  const [createLivenessApiData, setCreateLivenessApiData] =
    React.useState(null);

  React.useEffect(() => {
    const fetchCreateLiveness = async () => {
      try {
        const data = await startFaceLivenessSession();
        setCreateLivenessApiData({
          kycId: data.id,
          sessionId: data.kycIntegration.integrationId,
        });
        setLoading(false);
      } catch (err) {
        if (onActionFailed) {
          onActionFailed();
        }
      }
    };
    fetchCreateLiveness();
  }, [onActionFailed, dispatch]);

  const handleAnalysisComplete = async () => {
    if (!createLivenessApiData?.kycId || !createLivenessApiData?.sessionId)
      return;
    try {
      await getFaceLivenessSessionResult(createLivenessApiData.kycId);
      if (onActionComplete) {
        onActionComplete();
      }
    } catch (err) {
      if (err.response.status === 400) {
        const errorMessage = err.response?.data?.message || "";
        dispatchErrorMessage(errorMessage, dispatch);
        if (onActionFailed) {
          onActionFailed();
        }
      }
    }
  };

  return (
    <ThemeProvider>
      {loading ? (
        <Loader />
      ) : (
        <FaceLivenessDetector
          sessionId={createLivenessApiData?.sessionId}
          region="us-east-1"
          onAnalysisComplete={handleAnalysisComplete}
          onError={(error) => {
            dispatchErrorMessage(error.error.message, dispatch);
          }}
          displayText={dictionary}
        />
      )}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.user.me,
  };
};

export default connect(mapStateToProps, null)(FaceLiveness);
